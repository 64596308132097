import React, { useState } from 'react'
import { useNavigate } from "react-router-dom"
import Web3Modal from "web3modal";
import WalletConnect from "@walletconnect/web3-provider";
import {RotatingLines} from "react-loader-spinner";
import "./css/global.css"



//ライブラリー
const { ethers } = require("ethers");
const { MerkleTree } = require( 'merkletreejs');
const keccak256 = require( 'keccak256');

//プロジェクトごとに設定
//abi,bytecodeを取り込む。srcにリンクを張る。
const {abi} = require("./GFFCERC721A.json");
//main 本番
const contractAddress = "0x2F31f763911a79FCceFd3014B1FDb9dd8dBadf7f"; //NFTのdeploy時のアドレスを指定する。
const checkChainId = 1;//対応するChainId.  4=rinkeby。1=main polygon=137
//polygon
//const contractAddress = "0x50359f75F85e033969A182eAaa63B5b0D3A40ED7"; //NFTのdeploy時のアドレスを指定する。
//const checkChainId = 137;//対応するChainId.  4=rinkeby。1=main polygon=137
//rinkeby XSOLコントラクトテスト用
//const contractAddress = "0x75Ee17185f89b33A5163BD6AD60329c4f14fB176"; //NFTのdeploy時のアドレスを指定する。
//const checkChainId = 4;//対応するChainId.  4=rinkeby。1=main polygon=137
//rinkeby
//const contractAddress = "0x668b22D9Fc6032464b8D5E6A222ED49f08722809"; //NFTのdeploy時のアドレスを指定する。
//const checkChainId = 4;//対応するChainId.  4=rinkeby。1=main polygon=137
//local
//const contractAddress = "0x5FbDB2315678afecb367f032d93F642f64180aa3"; //NFTのdeploy時のアドレスを指定する。
//const checkChainId = 31337;//対応するChainId.  4=rinkeby。1=main polygon=137 local=31337


//定数
//const hash0 = ethers.constants.HashZero;

//walletの変更でイベント取りたい todo
//const providerFirst = new ethers.providers.Web3Provider(window.ethereum,"any");

//マークルツリーwhitelistを作成。
const whitelist = require("./whitelist");
const leafNodes = whitelist.map(addr => keccak256(addr));
const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
const rootHash = merkleTree.getRoot();//root
/*
const whitelist1 = require("./whitelist1");
const leafNodes1 = whitelist1.map(addr => keccak256(addr));
const merkleTree1 = new MerkleTree(leafNodes1, keccak256, { sortPairs: true });
const rootHash1 = merkleTree1.getRoot();//root
const whitelist2 = require("./whitelist2");
const leafNodes2 = whitelist2.map(addr => keccak256(addr));
const merkleTree2 = new MerkleTree(leafNodes2, keccak256, { sortPairs: true });
const rootHash2 = merkleTree2.getRoot();//root
*/

//マークルツリーのhexProofを得る
const getHexProof = (myaddress,mywhitelist,myleafNodes,mymerkleTree) => {
  const i = mywhitelist.indexOf(myaddress);
  if(i>=0){
    const claimingAddress = myleafNodes[mywhitelist.indexOf(myaddress)];
    const hexProof = mymerkleTree.getHexProof(claimingAddress);
    return(hexProof);
  }else{
    return([]);
  }
};

//パラメーター取得
function getParam(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

//グローバル変数
const providerOptions = {
  walletconnect: {
    package: WalletConnect, // required
    options: {
      infuraId: process.env.REACT_APP_INFURA_KEY // required
    }
  }
};
const web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions
});
let provider;
let signer;
let contract;

//メイン
const Main = () => {
  /*
  //検証用の認証。本番では削除する★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★
  const navigate = useNavigate();
  if(getParam('a') != "HNQf9NYV") {
    window.location.href = "https://google.com";
  };
  */



  const [price,setPrice] = useState(ethers.utils.parseEther("0"));//priceの変数とset関数と初期化.BigNumber
  const [isConnected,setConnected] = useState(false);//isConnectedの変数とset関数と初期化
  const [totalSupply,setTotalSupply] = useState(0);//totalSupplyの変数とset関数と初期化
  const [maxSupply,setMaxSupply] = useState(0);//maxSupplyの変数とset関数と初期化
  const [nftPerAddressLimit,setNftPerAddressLimit] = useState(0);//nftPerAddressLimitの変数とset関数と初期化
  const [onlyWhitelisted,setOnlyWhitelisted] = useState(false);//onlyWhitelistedの変数とset関数と初期化
  const [amount,setAmount] = useState(1);//amountの変数とset関数と初期化.mint数。
  const [canIMint,setCanIMint] = useState(false);//mint可能かの変数とset関数と初期化.
  const [isPaused,setIsPaused] = useState(true);//停止の変数とset関数と初期化.
  const [myAddress,setMyAddress] = useState("");//wallet addressの変数とset関数と初期化.
  const [hasWL,setHasWl] = useState(false);//wlを持ってる変数とset関数と初期化.
  const [hasWL1,setHasWl1] = useState(false);//wl1を持ってる変数とset関数と初期化.
  const [hasWL2,setHasWl2] = useState(false);//wl2を持ってる変数とset関数と初期化.
  const [instance, setInstance] = useState();//web3modalのinstance
  const [isLoading, setIsLoading] = useState();//ぐるぐる

  //処理
  const connect = async() => {
    try{
      const instance = await web3Modal.connect();
      setInstance(instance);
      provider = new ethers.providers.Web3Provider(instance);
      signer = provider.getSigner();
      contract = new ethers.Contract(contractAddress, abi, provider);  
    } catch{

    }
  };
  const disconnect = async () => {
    await web3Modal.clearCachedProvider();
    signer=false;
  };
  //デバッグ用。web3modalを表示するときに使う。
  //disconnect();

  //再接続
  React.useEffect(() => {
    if (web3Modal.cachedProvider) connect();
  }, []);
  //web3modalの状態更新
  React.useEffect(() => {
    if (instance?.on) {
      const handleDisconnect = () => {
        console.log("disconnect!");
        disconnect();
      };
      const handleChainChanged = async(checkChainId) => {
        const chainId = await getChainId();
        if(chainId != checkChainId){
          alert("Switch to Ethereum mainnet!");
          disconnect();
        }
      };

      instance.on("disconnect", handleDisconnect);
      instance.on("chainChanged", handleChainChanged);


      return () => {
        if (instance.removeListener) {
          instance.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [instance]);
  //address取得
  const getAddress = async() => { try {
    let address = await signer.getAddress();
    address = address.toLowerCase();//小文字に統一する
    return address;
  } catch { return false; }};
  //chainID取得
  const getChainId = async() => {try {
    return await signer.getChainId() 
  } catch { return false; }};
  //表示の更新
  const updateInfo = async() => {
    //接続チェック
    const address = await getAddress();
    const chainId = await getChainId();
    const isOK = (chainId === checkChainId && address ? true : false);
    if(!isOK){
      setConnected(false);
      setCanIMint(false);
      //console.log("Check Wallet Network.exit.");
      return;
    }
    if(address) setConnected(true);
    else setConnected(false);
    setMyAddress(address);//myAddressセット

    setTotalSupply((await contract.totalSupply()).toString());//totalSupplyをセット
    setMaxSupply((await contract.maxSupply()).toString());//maxSupplyをセット.最大のsupply数
    //Sale状況確認
    const isWlEnabled = await contract.isWlEnabled();
    const isPsEnabled = await contract.isPsEnabled();
    //Saleの状態
    let maxMintNum = 0;
    if(isWlEnabled && !isPsEnabled){//WL Sale
      setPrice(await contract.wlMintPrice());//WL金額をセット
      setOnlyWhitelisted(true);//onlyWhitelistedをセット.WhilteLIstSaleのときにtrue,PublicSaleではfalse
      //wlチェック
      const hexProof = getHexProof(address,whitelist,leafNodes,merkleTree);
      /*
      const hexProof1 = getHexProof(address,whitelist1,leafNodes1,merkleTree1);
      const hexProof2 = getHexProof(address,whitelist2,leafNodes2,merkleTree2);
      */
      const ret = await contract.isWhitelisted(address,hexProof,[],[]);
      setCanIMint(ret);//mintできるかどうか
      maxMintNum = await contract.getWhitelistedMaxMints(address,hexProof,[],[]);
      setNftPerAddressLimit(maxMintNum.toString());//nftPerAddressLimitをセット.一人当たりの最大mint数。
      const ret0 = await contract.hasWhitelistedOneWL(address,hexProof);
      setHasWl(ret0);
      /*
      const ret1 = await contract.hasWhitelistedOneWL1(address,hexProof1);
      setHasWl1(ret1);
      const ret2 = await contract.hasWhitelistedOneWL2(address,hexProof2);
      setHasWl2(ret2);
      */
      setIsPaused(false);//Saleしてる
    }else if(isPsEnabled){//Public Sale
      setPrice(await contract.psMintPrice());//PS金額をセット
      setOnlyWhitelisted(false);//onlyWhitelistedをセット.WhilteLIstSaleのときにtrue,PublicSaleではfalse
      maxMintNum = await contract.maxMintsPerPS();
      setNftPerAddressLimit(maxMintNum.toString());//nftPerAddressLimitをセット.一人当たりの最大mint数。
      setCanIMint(true);//mintできる
      setIsPaused(false);//Saleしてる
    }else{
      setIsPaused(true);//Saleしていない
      //wlチェック
      const hexProof = getHexProof(address,whitelist,leafNodes,merkleTree);
      /*
      const hexProof1 = getHexProof(address,whitelist1,leafNodes1,merkleTree1);
      const hexProof2 = getHexProof(address,whitelist2,leafNodes2,merkleTree2);
      */
      const ret = await contract.isWhitelisted(address,hexProof,[],[]);
      setCanIMint(ret);//mintできるかどうか
      const ret0 = await contract.hasWhitelistedOneWL(address,hexProof);
      setHasWl(ret0);
      /*
      const ret1 = await contract.hasWhitelistedOneWL1(address,hexProof1);
      setHasWl1(ret1);
      const ret2 = await contract.hasWhitelistedOneWL2(address,hexProof2);
      setHasWl2(ret2);
      */
    }
  };
  //Reactのインターバル　
  React.useEffect(function() {
    const intervalId = setInterval(function() {
      updateInfo();
    }, 2000);
    return function(){clearInterval(intervalId)};
  }, [price,isConnected,totalSupply,maxSupply,nftPerAddressLimit,onlyWhitelisted,amount,canIMint,isPaused,myAddress,hasWL,hasWL1,hasWL2]);
  window.onload = async() => {
    await updateInfo();
  };

  //Clickの処理.NFTをmint
  const buttonConnect = async() => {
    await connect();
    const address = await getAddress();
    const chainId = await getChainId();
    const isOK = (chainId === checkChainId && address ? true : false);
    if(!isOK){
      setConnected(false);
      setCanIMint(false);
      if(chainId && chainId != checkChainId)alert("Switch to Ethereum mainnet!");
      return;
    }else setConnected(true);
    await updateInfo();

  };
  //Clickの処理.Minus
  const buttonMinus = async() => {
    let amountVal = amount;
    --amountVal;
    if(amountVal < 1) amountVal = 1;
    setAmount(amountVal);
  };
  //Clickの処理.Plus
  const buttonPlus = async() => {
    let amountVal = amount;
    ++amountVal;
    if(amountVal > nftPerAddressLimit) amountVal = nftPerAddressLimit;
    setAmount(amountVal);
  };
  
  //Clickの処理.NFTをmint
  const buttonMint = async() => {
    try{
      const address = await getAddress();
      //署名を付けてコントラクトのmintを実行します。
      const hexProof = getHexProof(address,whitelist,leafNodes,merkleTree);
      /*
      const hexProof1 = getHexProof(address,whitelist1,leafNodes1,merkleTree1);
      const hexProof2 = getHexProof(address,whitelist2,leafNodes2,merkleTree2);
      */
      let tx;
      if(!isPaused && onlyWhitelisted){
        const valuePrice = ethers.BigNumber.from(price).mul(amount);
        tx = await contract.connect(signer).whitelistMint(amount,hexProof,[],[],{value:valuePrice});//metamaskの署名を要求する
        setIsLoading(true);
        await tx.wait();
        setIsLoading(false);
      }else if(!isPaused && !onlyWhitelisted){
        const valuePrice = ethers.BigNumber.from(price).mul(amount);
        tx = await contract.connect(signer).publicMint(amount,{value:valuePrice});//metamaskの署名を要求する
        setIsLoading(true);
        await tx.wait();
        setIsLoading(false);
      }
    }catch(e){
      setIsLoading(false);
      handleErrTx(e)
    };
  };
  //エラー処理.tx系
  const handleErrTx = async(e) => {
    let message = e.message;
    if(message.indexOf("insufficient funds for") >= 0) message="You have not enough ETH.";
    if(message.indexOf("You have no whitelistMint left") >= 0) message="You have no whitelistMint left.";
    if(message.indexOf("You have no publicMint left") >= 0) message="You have no publicMint left.";
    if(message.indexOf("No more NFT") >= 0) message="No more NFTs.";
    alert("Error : "+message);
  };
 
 
  //ここはJSXの書き方
  return (
    <>
    <img className="logo" src="./img/logo.png" />
    {!isConnected && <p className="btn-typ01"><button id="connect" onClick={buttonConnect}>CONNECT WALLET</button></p>}
    {isConnected && isPaused && <>
      <p className="ttl">NOT SALES.</p>
      {<p className="l-txt">{canIMint ? "YOU ARE WHITELISTED.":"YOU ARE NOT WHITELISTED."}</p>}
      {<p className="address">YOUR WALLET ADDRESS: {myAddress}</p>}
    </>}
    {isConnected && !isPaused && <>
      {<p className="ttl">{onlyWhitelisted ? "NOW WHITE LIST SALE!":"NOW PUBLIC SALE!"}</p>}
      {<p className="total-supply">{totalSupply}/{maxSupply}</p>}
      {parseInt(totalSupply) >= parseInt(maxSupply) &&  <p className="total-supply">SOLD OUT!</p>}
      {canIMint && 
        <div className="mint-cnt">
        <div className="cnt-block">
        <button id="minus" onClick={buttonMinus}>-</button>
        <p className="num">{amount}</p>
        <button id="plus" onClick={buttonPlus}>+</button>
        </div>
        <p className="s-txt">MAX MINT: {nftPerAddressLimit}</p>
        <p className="s-txt">PRICE: {ethers.utils.formatEther(price)} ETH</p>
        </div>}      
      {canIMint && <p className="btn-typ03"><button id="mint" onClick={buttonMint}>MINT</button></p>}
      {<p className="address">YOUR WALLET ADDRESS: {myAddress}</p>}
    </>}
    </>
  );
}
export default Main;
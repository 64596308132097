import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./Main";


const Routers = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Main/>}/>
            </Routes>
        </BrowserRouter>
    )
}
export default Routers